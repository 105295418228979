import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useCallback } from "react";
import { DDLogoOnlyDD } from "../../../components/Logo/DDLogoOnlyDD";
import { useStoreValueWithDaysLimit } from "../../../hooks/useStoreValueWithDaysLimit/useStoreValueWithDaysLimit";
import { CloseIcon } from "../../../ecosystems/mealplans/mealplan/icons/CloseIcon";
import { sendEvent } from "../../../utils/gtm";
import AppStoreBadge from "../../welcome/AppStoreBadge";
import PlayStoreBadge from "../../welcome/PlayStoreBadge";
import { useTranslations } from "../translations";
import * as React from "react";

export function TrackingCTADownloadApp() {
  const tt = useTranslations();
  const [isExpired, reset] = useStoreValueWithDaysLimit("dd/tracking/ad");
  const onCloseHandle = useCallback(() => reset(), [reset]);

  const imageData = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "tracking-cta-stay-on-track-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 546)
        }
      }

      mobile: file(relativePath: { eq: "tracking-cta-stay-on-track-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 400)
        }
      }
    }
  `);

  const onClickOnAppStore = useCallback(() => {
    sendEvent({
      eventName: "tracking",
      action: "track_banner_IOS_download",
    });
  }, []);

  const onClickOnPlayStore = useCallback(() => {
    sendEvent({
      eventName: "tracking",
      action: "track_banner_android_download",
    });
  }, []);

  return (
    <div className={`px-4 pt-4 ${!isExpired ? "hidden md:block" : ""}`}>
      <div
        className="relative flex flex-col w-full md:flex-row md:items-end items-center bg-sand rounded-xl pt-8 md:mx-auto"
        style={{ maxWidth: "1200px" }}
      >
        <button className="absolute top-2 right-2 hover:opacity-50 md:hidden">
          <CloseIcon onClick={onCloseHandle} />
        </button>
        <div className="flex md:absolute md:top-6 md:right-6 w-full md:w-fit justify-center mb-8 md:mb-0">
          <div className="w-[74px] h-[74px] md:h-[68px] md:w-[68px] bg-green rounded-xl shadow-lg flex justify-center items-center">
            <DDLogoOnlyDD className="h-[51px] md:h-[46px]" />
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/3 justify-center px-8">
          <h1 className="m-0 text-blue font-sans font-medium text-center md:text-left text-3xl md:text-5xl mb-4">
            {tt.trackingSectionTitle}
          </h1>
          <h2 className="m-0 text-blue font-sans font-medium text-center md:text-left text-xl md:text-2xl">
            {tt.trackingSectionAddingWeightInfoPrefix}
          </h2>
          <div className="flex w-full justify-center md:justify-start gap-4 my-8">
            <a
              href="https://apps.apple.com/us/app/diet-doctor-eat/id1440002708"
              target="_blank"
              rel="noreferrer"
              onClick={onClickOnAppStore}
            >
              <AppStoreBadge height="36" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.dietdoctor.EatApp"
              target="_blank"
              rel="noreferrer"
              onClick={onClickOnPlayStore}
            >
              <PlayStoreBadge height="36" />
            </a>
          </div>
        </div>
        <div className="flex md:justify-center md:grow overflow-hidden rounded-b-xl">
          <GatsbyImage
            image={imageData.mobile?.childImageSharp?.gatsbyImageData}
            className="md:!hidden align-bottom"
            alt={tt.trackingSectionTitle}
          />
          <GatsbyImage
            image={imageData.desktop?.childImageSharp?.gatsbyImageData}
            className="!hidden md:!block align-bottom"
            alt={tt.trackingSectionTitle}
          />
        </div>
      </div>
    </div>
  );
}
