import * as React from "react";

export function DDLogoOnlyDD(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 99 132" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#fff">
        <path d="m.5 131.4v-16.4h32.9c27.2 0 49.3-22.1 49.3-49.3s-22.1-49.3-49.3-49.3h-32.9v-16.4h32.9c36.3 0 65.8 29.4 65.8 65.8s-29.4 65.8-65.8 65.8h-32.9zm0 0" />
        <path d="m33.4 32.8h-32.9v65.8h32.9c18.2 0 32.9-14.7 32.9-32.9s-14.7-32.9-32.9-32.9zm0 49.3h-16.4v-16.4-16.4h16.4c9.1 0 16.4 7.4 16.4 16.4s-7.3 16.4-16.4 16.4zm0 0" />
      </g>
    </svg>
  );
}
