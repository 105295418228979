import React, { useMemo } from "react";
import { useContext } from "react";
import Loading from "../../components/Loading/Loading";
import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import { SEOReactHelmet } from "../../components/SEO/SEOReactHelmet";
import { TrackingCTADownloadApp } from "./components/TrackingCTADownloadApp";
import { TrackingPromotion } from "./components/TrackingPromotion";
import TrackingSection from "./components/TrackingSection";
import { useTranslations } from "./translations";

const TrackingPage = () => {
  const tt = useTranslations();

  const { loading, loggedIn, user: userData } = useContext(LoginContext);
  const notLoggedIn = useMemo(() => !loading && !loggedIn, [loading, loggedIn]);
  const isLoggedIn = useMemo(() => !loading && loggedIn, [loading, loggedIn]);

  return (
    <>
      <SEOReactHelmet title={tt.trackingPage} />
      {isLoggedIn && <TrackingCTADownloadApp />}
      <div className="md:mx-auto w-full" style={{ maxWidth: "1200px" }}>
        {loading && <Loading />}
        <section className="w-full p-4 md:p-0">
          {!loading && userData && (
            <div className="md:pb-10 md:pt-4">
              <TrackingSection />
            </div>
          )}
          {notLoggedIn && <TrackingPromotion />}
        </section>
      </div>
    </>
  );
};

// ts-prune-ignore-next
export default TrackingPage;
