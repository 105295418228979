import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { useNavigate } from "../../../components/Link/Link";
import { SignUpPage, SignUpStep } from "../../../pages";
import { useTranslations } from "../translations";
import { Button } from "./Button";

export const TrackingPromotion = () => {
  const tt = useTranslations();
  const navigate = useNavigate();
  const handleNavigateToNewMemberPage = () =>
    navigate({
      to: SignUpPage,
      params: { step: SignUpStep.ACCOUNT, content: "tracking" },
    });

  const imageData = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "tracking-cta-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 590)
        }
      }

      mobile: file(relativePath: { eq: "tracking-cta-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    }
  `);

  return (
    <div className="my-4">
      <div className="flex items-center my-4">
        <div className="flex-grow border-b border-light-grey"></div>

        <div className="px-3 uppercase text-grey tracking-widest text-xs">{tt.ddPlusMemberText}</div>
        <div className="flex-grow border-b border-light-grey"></div>
      </div>

      <div className="bg-sand rounded-xl flex flex-col md:flex-row overflow-hidden">
        <div className="p-5 md:p-8 w-full md:w-1/2 self-center">
          <h2 className="display-xs md:display-s text-black mt-15 md:mt-0 mb-3">
            {tt.promotionalBannerTitle} <span className="block">Diet Doctor Plus</span>
          </h2>
          <p className="text-black text-base m-0">{tt.promotionalBannerContent}</p>
          <Button label={tt.freeTrial} onClick={handleNavigateToNewMemberPage} />
        </div>
        <div className="w-full md:w-1/2 flex justify-end">
          <div className="hidden md:block self-end w-full">
            <GatsbyImage
              image={imageData.desktop?.childImageSharp?.gatsbyImageData}
              className="align-bottom"
              alt={tt.promotionalBannerTitle}
            />
          </div>
          <div className="block md:hidden self-end w-full">
            <GatsbyImage
              image={imageData.mobile?.childImageSharp?.gatsbyImageData}
              className="align-bottom"
              alt={tt.promotionalBannerTitle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
