import React from "react";

type ButtonProps = {
  label: string;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: () => void;
  className?: string;
};

export const Button = ({ type = "button", label, onClick }: ButtonProps) => (
  <button
    tabIndex={0}
    type={type}
    onClick={onClick}
    className="mt-5 rounded-full px-8 py-3 text-base font-medium bg-green text-white hover:bg-light-green"
  >
    {label}
  </button>
);
